import React, {useEffect, useState} from 'react';
import {ethers} from 'ethers';
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import AccessPage from './AccessPage';

import './App.css';
import './AccessPage.css'
import {FiInfo} from 'react-icons/fi';

function Main() {

    const [backendData, setBackendData] = useState([])
    const [hasAccess, setHasAccess] = useState(null);
    const [connectedAddress, setConnectedAddress] = useState(null);

    async function connectWallet() {
        if (typeof window.ethereum !== 'undefined') {
            window.ethereum
                .request({method: 'eth_requestAccounts'})
                .then(async (accounts) => {
                    const userProvider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = userProvider.getSigner();
                    setConnectedAddress(await signer.getAddress());
                })
                .catch((error) => {
                    console.error("Error connecting wallet:", error);
                    alert("Error connecting to your wallet. Please try again.");
                });
        } else {
            alert("Please install MetaMask or another Ethereum wallet.");
        }
    }

    async function checkOwnership() {
        if (connectedAddress) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            fetch("/challenge")
                .then(response => response.json())
                .then(json => signer.signMessage(json["challenge"]))

                .then(signed => fetch("/authenticate", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({signature: signed})
                }))
                .then(response => response.json())
                .then(json => {
                    if (json.status == "ok") {
                        setHasAccess(true);
                    } else {
                        throw new Error(json.reason)
                    }
                })

                .catch(error => {
                    console.error("Error checking balance:", error);
                    setHasAccess(false)
                })
        } else {
            alert("Please connect your wallet first.");
        }
    }

    return (

        <>
            <div className="page-description">
                <div className="gif-row">
                    <img src="/poapbcrlwinter.gif" alt="POAP BCRL Winter GIF" class="poapbcrlwinter-gif"/>
                    <img src="/poapbcrlsummer.gif" alt="POAP BCRL Summer GIF" class="poapbcrlwinter-gif"/>
                    <img src="/jesuspoap.gif" alt="Jesus POAP GIF" />
                </div>
                <div className="column">
                    <p>Welcome to the Token-Gated Access Page of Blockchain-Reallabor. Depending on the POAP you own,
                        you will be directed to relevant insights and content.</p>
                </div>
                <div className="column">
                    <p>If you don't own the "Blockchain Reallabor Studio 6 -
                        Winter Season 2024 POAP" by Blockchain Reallabor you need to visit Studio 6 and be part of the family.</p>
                </div>
            </div>

            <div className="container">

                {(!connectedAddress && !hasAccess) && (
                    <button className="button" onClick={connectWallet}>
                        Connect your Wallet
                    </button>
                )}
                {connectedAddress && (
                    <p className="connected-message" style={{color: '#29B9DB'}}>
                        Connected with:<br/>
                        {connectedAddress}
                    </p>
                )}

                {(!hasAccess && connectedAddress) && (
                    <button className="button" onClick={checkOwnership}>
                        Check Access
                    </button>
                )}

                {hasAccess === true && (
                    <div>
                        <Link to="/access" className="button" style={{textDecoration: 'none'}}
                              onClick={() => console.log('View Insights button clicked')}>
                            View Insights
                        </Link>
                        <p>You have access to the insights!</p>
                        <Routes>
                            <Route path="/access" element={<AccessPage/>}/>
                        </Routes>
                    </div>
                )}
                {hasAccess === false && (
                    <div className="popup">
                        <div className="popup-header">
    <span className="popup-icon">
        <FiInfo/>
    </span>
                            <h3>Sorry, you don't have access!</h3>
                        </div>
                        <ul className="popup-list">
                            <li className="popup-list-item">
                                <span className="popup-icon">•</span> Check if you're in the right network.
                            </li>
                            <li className="popup-list-item">
                                <span className="popup-icon">•</span> Check if you're connected with the right account.
                            </li>
                            <li className="popup-list-item">
                                <span className="popup-icon">•</span> Re-connect your Wallet.
                            </li>
                        </ul>
                        <button className="button popup-button" onClick={() => setHasAccess(null, window.location.reload())}>
                            Close
                        </button>
                    </div>

                )}

            </div>
        </>
    )
}


export default Main;